<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Kelebihan Kami</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Kelebihan Kami</h2>
						</div>
						<div class="row">
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-1.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
							
							<div class="col-md-12"><hr class="mt-0 mb-2" /></div>
							
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-2.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
							
							<div class="col-md-12"><hr class="mt-0 mb-2" /></div>
							
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-3.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
							
							<div class="col-md-12"><hr class="mt-0 mb-2" /></div>
							
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-4.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
							
							<div class="col-md-12"><hr class="mt-0 mb-2" /></div>
							
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-5.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
							
							<div class="col-md-12"><hr class="mt-0 mb-2" /></div>
							
							<div class="col-md-3 d-flex align-items-center justify-content-center">
								<img src="@/assets/images/kelebihan-6.jpg" class="card-img-top round shadow w-sm-75 w-xs-75" alt="staticImage" />
							</div>
							<div class="col-md-9 text-left">
								<p>Duniamurah.com  merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun. Sesuai perkembangan saat ini, sejak tahun 2020 Duniamurah.com juga mulai melakukan penjualan bahan Makanan ,  Minuman dan produk kebutuhan kantor. </p>
							</div>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>